<template>
  <div>

    <div v-if="isshowgrid">
      <va-card title="Backup">
        <template slot="actions">
          <va-button color="success" icon="fa fa-database" @click="backup()" >Backup</va-button>
        </template>
        <template slot="actions" class="text-right">
          <va-button color="success" icon="entypo entypo-ccw" @click="add()">Restore</va-button>
        </template>
        <!-- <div class="flex xs12 sm6">
          <va-date-picker
            v-model="current_date"
          />
        </div>
        <div class="d-flex justify--center mt-3">
          <va-button type="submit" class="my-0" @click.prevent="createBackup()" style="background-color: #2c82e0;">Submit</va-button>
        </div> -->
      </va-card>
    </div>
    <div v-else-if='isshowForm'>
      <va-card title="Restore Backup">
        <template slot="actions">
          <va-button color="success" icon="fa fa-database" @click="backup()" >Backup</va-button>
        </template>
        <input type="file" @change="checkBackupfile" accept=".7z,.7zip"/>
        <div class="d-flex justify--center mt-3">
          <va-button type="submit" icon="entypo entypo-ccw" class="my-0" @click.prevent="restoreBackup()">Restore</va-button>
        </div>
      </va-card>
    </div>
    <va-modal
      v-model="showRemoveModal"
      title="DB Backup"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="createBackup()"
      @cancel="list()">
    </va-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'backup',
  data () {
    return {
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      genres_data: [],
      isshowgrid: true,
      isshowForm: true,
      isCreate: false,
      isUpdate: false,
      GenresErrors: [],
      priorityErrors: [],
      tar_file: '',
      current_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      showRemoveModal: false,
      message: '',
    }
  },
  computed: {
    fileupload_check () {
      if (this.tar_file != '') {
        return true
      } else {
        this.call_notification('please Upload a file')
        return false
      }
    },
  },
  methods: {
    add () {
      this.isshowgrid = false
      this.isshowForm = true
    },
    checkBackupfile (element) {
      var file = element.target.files[0]
      this.tar_file = file
      var filename = file.name
      if (filename >= 0) {
        this.call_notification('please check the file name [year-month-date]')
      }
    },
    createBackup () {
      var payload = {
        current_date: this.current_date,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'backup', payload).then(responseData => {
        if (responseData && responseData.body) {
          loader.hide()
          var element = document.createElement('a')
          element.setAttribute('href', 'data:text/zip;base64,' + escape(responseData.body.data))
          element.setAttribute('download', responseData.body.filename)

          element.style.display = 'none'
          document.body.appendChild(element)

          element.click()
          Vue.notify({
            text: 'Downloaded Successfully',
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          loader.hide()
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    restoreBackup () {
      if (!this.fileupload_check) return
      if (!confirm('Are you sure want to Restore?')) return
      var payload = new FormData()
      payload.append('tar_file', this.tar_file)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'backup/restore', payload).then(responseData => {
        if (responseData && responseData.body) {
          loader.hide()
          Vue.notify({
            text: 'Restored Successfully',
            type: 'success',
          })
          this.list()
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          loader.hide()
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    backup () {
      this.showRemoveModal = true
      this.message = 'Press Confirm to Backup DB?'
    },
    list () {
      this.isshowgrid = true
      this.isshowForm = false
    },
    call_notification (message) {
      Vue.notify({
        text: message,
        type: 'error',
      })
    },
  },
}
</script>
